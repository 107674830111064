document.addEventListener("turbolinks:load", function() {
    let requiredFields = [...document.getElementsByClassName('required-field')]
    let missingAddressFields = requiredFields.some((field) => field.value === "");
    if (missingAddressFields) {
        $('#survey-submit-btn').attr('disabled', true);
        $('#survey-submit-btn').before(
            `<p class="missing-address-fields">Please check that you have chosen a valid street address</p>`
        );
    }

    $('.survey-rating-range-selector').on('change', function() {
        let valuePrompt = $(this).siblings('.rating-values').children('.current-range-value');
        valuePrompt.text("Current value =  " + $(this).val());
    });

    $('.autocomplete_address').on('change', function() {
        let thisInput = $(this);
        setTimeout(function() {
            let requiredFields = [...document.getElementsByClassName('required-field')]
            let addressFields = [...$(thisInput).siblings('.required-field')];
            let missingFields = addressFields.some((field) => field.value === "");
            let missingRequiredFields = requiredFields.some((field) => field.value === "");
            let address = addressFields.map((field) => field.value).join(', ');

            thisInput.val(address);

            if (missingFields) {
                thisInput.siblings('.missing-address-fields').remove();
                $('#survey-submit-btn').siblings('.missing-address-fields').remove();
                thisInput.css("border-color", "red");
                thisInput.after(
                    `<p class="missing-address-fields">Please select a valid street address from the dropdown (either your address or that of your food bank);<br>
                    Street, City, State, Postcode, and Country are required.</p>`
                );
                $('#survey-submit-btn').attr('disabled', true);
                $('#survey-submit-btn').before(`<p class="missing-address-fields">Please check that you have chosen a valid street address</p>`);
            } else {
                thisInput.css("border-color", "");
                thisInput.siblings('.missing-address-fields').remove();
            }

            if (!missingRequiredFields) {
                $('#survey-submit-btn').attr('disabled', false);
                $('#survey-submit-btn').siblings('.missing-address-fields').remove();
            }
        }, 500);
    });
});

